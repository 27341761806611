import { ref, UnwrapRef } from "vue";
import { SearchAndPagingInterface } from "@/models/searchAndPaging/SearchAndPaging.interface";

type GetListFunction<T> = (
  req: SearchAndPagingInterface,
  signal: AbortSignal
) => Promise<T[]>;

export function useSelectWithLazyLoading<T>(getList: GetListFunction<T>) {
  const takeDefaultValue = 10;

  const items = ref<T[]>([]);
  const searchQuery = ref<string>("");
  const take = ref<number>(takeDefaultValue);
  const isLoading = ref<boolean>(false);
  const isComplete = ref<boolean>(false);
  let abortController: AbortController | null = null;

  const setSearchQuery = (value: string) => {
    searchQuery.value = value;
    isComplete.value = false;
  };

  async function fetchData(skip: number): Promise<T[]> {
    abortController?.abort();
    abortController = new AbortController();

    isLoading.value = true;
    const req: SearchAndPagingInterface = {
      searchQuery: searchQuery.value,
      skip: skip,
      take: take.value
    };

    const items: T[] = [];

    const data = await getList(req, abortController.signal);

    if (data.length === 0) {
      isComplete.value = true;
      isLoading.value = false;
      return [];
    }

    if (data.length < take.value) isComplete.value = true;

    data.map(item => items.push(item));

    abortController = null;
    isLoading.value = false;
    return items;
  }

  async function onSearch(value: string): Promise<void> {
    setSearchQuery(value);

    items.value = (await fetchData(0)) as UnwrapRef<T[]>;
  }

  async function onLoadMore(): Promise<void> {
    if (isComplete.value) return;

    const result = (await fetchData(items.value.length)) as UnwrapRef<T[]>;
    items.value.push(...result);
  }

  return {
    items,
    searchQuery,
    take,
    isComplete,
    isLoading,
    onSearch,
    onLoadMore
  };
}
