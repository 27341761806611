



























































































































import { ListItemInterface } from "@/models/global/ListItem.interface";
import {
  getContainerTypes,
  getDangerousCargos,
  getLoadTypes
} from "@/api/infoteka";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch
} from "vue";
import BaseInput from "@/components/UI/BaseInput.vue";
import useVuelidate from "@vuelidate/core";
import { maxLength, requiredIf } from "@vuelidate/validators";
import { LoadTypesInterface } from "@/models/infoteka/loadTypes.interface";
import { DangerousCargoItemInterface } from "@/models/order/DangerousCargoItem.interface";
import { useAutocompleteStore } from "@/store/useAutocompleteStore";
import { PlaceOptionsItemInterface } from "@/models/order/PlaceOptionsItem.interface";
import { useSelectWithLazyLoading } from "@/models/searchAndPaging/useSelectWithLazyLoading";
import SelectWithLazyLoading from "@/components/UI/SelectWithLazyLoading.vue";

export default defineComponent({
  name: "PlaceOptions",
  components: { SelectWithLazyLoading, BaseInput },
  props: {
    id: {
      type: String as PropType<string>,
      default: ""
    },
    options: {
      type: Object as PropType<PlaceOptionsItemInterface>,
      required: true
    },
    hasEdm: {
      type: Boolean,
      default: false
    },
    isFillFullVolume: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const {
      searchQuery: containerTypesSearchQuery,
      items: containerTypes,
      isLoading: containerTypesSearchLoading,
      onSearch: onSearchContainerType,
      onLoadMore: onLoadMoreContainerType
    } = useSelectWithLazyLoading(getContainerTypes);

    const {
      searchQuery: dangerousCargoItemsSearchQuery,
      items: dangerousCargoItems,
      isLoading: dangerousCargoItemsSearchLoading,
      onSearch: onSearchDangerousCargoItem,
      onLoadMore: onLoadMoreDangerousCargoItem
    } = useSelectWithLazyLoading(getDangerousCargos);

    const v$ = useVuelidate(
      {
        cargoType: {
          required: requiredIf(() => props.hasEdm)
        },
        cargoShippingTitle: {
          required: requiredIf(() => props.hasEdm),
          max: maxLength(1000)
        },
        cargoCondition: {
          required: requiredIf(() => props.hasEdm),
          max: maxLength(1000)
        },
        packingMethod: {
          required: requiredIf(() => props.hasEdm),
          max: maxLength(1000)
        },
        containerType: {
          required: requiredIf(() => props.hasEdm)
        }
      },
      props.options
    );

    const loadTypes = ref<LoadTypesInterface[]>([]);
    const isContainer = computed<boolean>(() => {
      return props.options.cargoType?.code == "Kontejner";
    });
    const isDangerousCargo = computed<boolean>(() => {
      return props.options.cargoType?.code == "Opasnijgruz";
    });
    const autocompleteStore = useAutocompleteStore();

    const cargoConditions = computed(() => {
      return autocompleteStore.cargoConditions;
    });
    const packingMethods = computed(() => {
      return autocompleteStore.packingMethods;
    });

    onMounted(async () => {
      loadTypes.value = await getLoadTypes();
    });

    watch(isContainer, val => {
      if (val) {
        props.options.cargoShippingTitle = "Контейнер";
        return;
      }

      props.options.cargoShippingTitle = "";
      props.options.dangerousCargoData = null;
    });

    function onDangerousCargoInput(item: DangerousCargoItemInterface | null) {
      if (item == null) {
        props.options.dangerousClassCode = "";
        return;
      }

      props.options.dangerousClassCode = item.class ?? "";
    }

    function onCargoTypeChange() {
      props.options.cargoShippingTitle = "";
      props.options.dangerousCargoData = null;
    }

    return {
      v$,
      dangerousCargoItems,
      dangerousCargoItemsSearchQuery,
      dangerousCargoItemsSearchLoading,
      loadTypes,
      isContainer,
      isDangerousCargo,
      cargoConditions,
      packingMethods,
      containerTypes,
      containerTypesSearchQuery,
      containerTypesSearchLoading,
      onDangerousCargoInput,
      onCargoTypeChange,
      onSearchContainerType,
      onSearchDangerousCargoItem,
      onLoadMoreContainerType,
      onLoadMoreDangerousCargoItem
    };
  },
  data() {
    return {
      temperatureRegimes: [
        {
          id: "Без режима",
          title: "Без режима"
        },
        {
          id: "2 — 6 °C",
          title: "2 — 6 °C"
        },
        {
          id: "12 — 18 °C",
          title: "12 — 18 °C"
        },
        {
          id: "5 — 25 °C",
          title: "5 — 25 °C"
        },
        {
          id: "0 — -5 °C",
          title: "0 — -5 °C"
        },
        {
          id: "-18 °C",
          title: "-18 °C"
        },
        {
          id: "-25 °C",
          title: "-25 °C"
        }
      ] as ListItemInterface[]
    };
  }
});
