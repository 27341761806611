






























































import { defineComponent, PropType } from "vue";
import { ListItemInterface } from "@/models/global/ListItem.interface";

export default defineComponent({
  name: "SelectWithLazyLoading",
  props: {
    searchQuery: {
      type: String as PropType<string>,
      default: ""
    },
    items: {
      type: [] as PropType<ListItemInterface[]>,
      required: true
    },
    label: {
      type: String as PropType<string>,
      default: ""
    },
    value: {
      type: [] as PropType<string[]>,
      required: true
    },
    multiple: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    error: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    id: {
      type: String as PropType<string>,
      default: ""
    },
    required: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  methods: {
    isLastItem(item: ListItemInterface) {
      if (this.items.length > 1)
        return item === this.items[this.items.length - 1];
    },
    onIntersect(event: IntersectionObserverEntry[]) {
      if (event[0].isIntersecting) {
        this.$emit("load-more");
      }
    },
    onFocus() {
      this.$emit("search", "");
    },
    onBlur() {
      if (this.value == null || Array.isArray(this.value)) return;

      this.$emit("search", this.value?.title);
    },
    searchInput(value: string | null) {
      if (value === null) return;
      this.$emit("search", value);
    },
    inputValue(value: any[] | any | string) {
      if (typeof value === "string") return;

      if (Array.isArray(value)) {
        value = value.filter(x => typeof x != "string");
      }

      this.$emit("input", value);
    }
  }
});
