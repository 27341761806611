import { computed, ComputedRef, inject, Ref, ref, watch } from "vue";
import {
  ExecutorRoleTypeEnum,
  OrganizationViewInterface
} from "@/models/global/OrganizationView.interface";
import {
  getDefaultConditions,
  getOrganizationsToForwardOrder
} from "@/api/order";
import { OrderTypeEnum } from "@/models/order/OrderType.enum";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import { OrganizationGroupInterface } from "@/models/OrganizationGroup/OrganizationGroup.interface";
import { useUserStore } from "@/store/useUserStore";
import { AvailableGenContractInterface } from "@/models/forwarding/GetDefaultConditionsResponse.interface";
import { useConditionsDescription } from "@/components/order/compositions/useConditionsDescription";

export default function useForwarding(order: Ref<OrderItemInterface>) {
  const organizations = ref<OrganizationViewInterface[]>([]);
  const organizationGroups = ref<OrganizationGroupInterface[]>([]);
  const selectedOrganizationGroups = ref<OrganizationGroupInterface[]>([]);
  const initPartners = ref<OrganizationViewInterface[]>([]);
  const availableGenContracts = ref<AvailableGenContractInterface[]>([]);
  const search = ref<string | null>("");
  const organizationsLoading = ref<boolean>(false);
  const isFocused = ref<boolean>(false);
  const onlyEdmOrganizations = ref<boolean>(false);
  let searchTimeout: number | undefined = undefined;
  let searchAbortController: AbortController | null = null;
  let loadPartnersAbortController: AbortController | null = null;
  const userStore = useUserStore();
  const edmAvailable: ComputedRef<boolean> | undefined = inject("edmAvailable");

  const showOnlyEdmOrganizationsCheck = computed(() => {
    return userStore.ownerCountry === "RUS" && (edmAvailable?.value ?? false);
  });

  const isVisibleToOpenHubCheck = computed(() => {
    return order.value.isVisibleToOpenHub;
  });

  const filteredForwardingOrganizations = computed(() => {
    return order.value.forwardToOrganizations
      ? order.value.forwardToOrganizations.filter(
          o => o?.isInBlackList === false
        )
      : [];
  });

  const isInBlackListWarning = computed(() =>
    order.value.forwardToOrganizations?.some(o => o.isInBlackList)
      ? "В списке выбранных организаций, есть организация из «Черного списка»"
      : ""
  );

  const allPartnersSelected = computed(() =>
    initPartners.value.every(
      o => order.value.forwardToOrganizations?.some(f => f.id === o.id) ?? false
    )
  );
  const selectedNotPartners = computed<OrganizationViewInterface[]>(() => {
    return (
      filteredForwardingOrganizations.value?.filter(
        item =>
          !item.genContractId ||
          (order.value.executorRoleType === ExecutorRoleTypeEnum.Any &&
            item.partnerExecutorRoleType !== ExecutorRoleTypeEnum.Any)
      ) ?? []
    );
  });
  const selectedNotPartnersTitles = computed<string>(() => {
    return selectedNotPartners.value
      .map(item => {
        if (item.partnerExecutorRoleType == null) return item.title;

        return (
          item.title +
          (item.partnerExecutorRoleType === ExecutorRoleTypeEnum.Forwarding
            ? "(перевозчик)"
            : "(экспедитор)")
        );
      })
      .join(", ");
  });
  const selectedPartners = computed<OrganizationViewInterface[]>(() => {
    return (
      filteredForwardingOrganizations.value?.filter(
        item => item.genContractId
      ) ?? []
    );
  });
  const noDataMessage = computed<string>(() => {
    return search.value != null && search.value.length >= 3
      ? "Не найдено организаций для поиска"
      : "Минимум 3 символа для поиска";
  });

  const onUpdateSelectedGroups = (
    newSelectedItems: OrganizationGroupInterface[]
  ) => {
    selectedOrganizationGroups.value = newSelectedItems;
  };

  const getDefaultConditionValues = async () => {
    if (order.value.executorOrganization == null) return;

    availableGenContracts.value = [];

    const res = await getDefaultConditions(
      order.value.executorOrganization.id,
      order.value.executorRoleType
    );

    if (!res.isSuccess) return;

    availableGenContracts.value = res.entity.availableGenContracts;
    const defaultConditions = res.entity.defaultConditions;
    order.value.conditions.defermentOfPaymentDays =
      order.value.conditions.defermentOfPaymentDays ||
      defaultConditions.daysAfterDocumentsReceiveForPayment;
    order.value.conditions.documentExchangeDaysAfterFreightUnloading =
      order.value.conditions.documentExchangeDaysAfterFreightUnloading ||
      defaultConditions.documentExchangeDaysAfterFreightUnloading;
    order.value.conditions.documentsFormat =
      order.value.conditions.documentsFormat ||
      defaultConditions.documentsFormat;
    order.value.conditions.otherConditions =
      order.value.conditions.otherConditions ||
      defaultConditions.otherConditionsText;
  };

  const _setInitPartnersToList = () => {
    organizations.value = initPartners.value;
  };

  const loadPartners = async (orgId: string) => {
    try {
      loadPartnersAbortController?.abort();

      loadPartnersAbortController = new AbortController();

      const res = await getOrganizationsToForwardOrder(
        order.value.executorRoleType,
        orgId,
        "",
        true,
        loadPartnersAbortController.signal
      );

      if (!res.isSuccess) return;

      initPartners.value = res.entity.filter(x => {
        return !onlyEdmOrganizations.value || x.isEdmAvailable;
      });

      if (!isFocused.value || (search.value?.length ?? 0) > 0) return;

      _setInitPartnersToList();
    } catch (e) {
      if (e.name === "AbortError") return;

      throw e;
    }
  };
  const selectOrderType = async (orderType: OrderTypeEnum) => {
    if (
      orderType == OrderTypeEnum.ToYourself ||
      orderType == OrderTypeEnum.ToHub
    ) {
      order.value.forwardToOrganizations = [];
      order.value.isAuctionAutoExtend = false;
      order.value.isAuctionAutoComplete = false;
      order.value.extendTimeLimit = "";
      order.value.auctionExtendActivationTime = "";
      order.value.auctionExtendTime = "";
    }
    if (order.value.executorOrganization == null) return;

    if (orderType === OrderTypeEnum.ToHub) {
      await getDefaultConditionValues();
      await loadPartners(order.value.executorOrganization.id);
    }
  };
  const selectExecutorRoleType = async () => {
    organizations.value = [];
    order.value.forwardToOrganizations = [];

    if (order.value.executorOrganization == null) return;
    await getDefaultConditionValues();
    await loadPartners(order.value.executorOrganization.id);
  };

  const selectAllPartners = () => {
    if (allPartnersSelected.value) return;

    const notSelectedItems = initPartners.value.filter(
      item =>
        order.value.forwardToOrganizations?.every(org => org.id !== item.id) ??
        true
    );

    if (order.value.forwardToOrganizations == null) {
      order.value.forwardToOrganizations = notSelectedItems;
      return;
    }

    order.value.forwardToOrganizations.push(...notSelectedItems);
  };

  function onSelectGroup({
    item,
    selected
  }: {
    item: OrganizationGroupInterface;
    selected: boolean;
  }) {
    let newOrganizations = order.value.forwardToOrganizations || [];
    if (selected) selectedOrganizationGroups.value.push(item);

    item.organizations.forEach(org => {
      if (selected) {
        !newOrganizations.find(o => o.id === org.id) &&
          newOrganizations.push(org);
      } else {
        newOrganizations = newOrganizations.filter(item => item.id !== org.id);
      }
    });
    order.value.forwardToOrganizations = newOrganizations;
    order.value.forwardToOrganizationGroups = selectedOrganizationGroups.value.map(
      item => item.id
    );
  }

  function onAutocompleteChange() {
    if (!order.value.isAuctionAutoComplete) {
      order.value.isAuctionAutoExtend = false;
      order.value.extendTimeLimit = "";
      order.value.auctionExtendActivationTime = "";
      order.value.auctionExtendTime = "";
    }
  }

  function onSearchInput(value: string | null) {
    search.value = value;

    if (value == null) value = "";

    clearTimeout(searchTimeout);
    searchAbortController?.abort();

    if (order.value.executorOrganization == null) return;

    if (value.length == 0) _setInitPartnersToList();

    if (value.length < 3) return;

    searchTimeout = setTimeout(() => {
      if (order.value.executorOrganization == null) return;
      searchAbortController = new AbortController();
      organizationsLoading.value = true;
      getOrganizationsToForwardOrder(
        order.value.executorRoleType,
        order.value.executorOrganization.id,
        value ?? "",
        false,
        searchAbortController?.signal
      )
        .then(res => {
          if (!res.isSuccess) {
            organizations.value = [];
            return;
          }

          organizations.value = res.entity.filter(x => {
            return !onlyEdmOrganizations.value || x.isEdmAvailable;
          });
        })
        .catch(e => {
          if (e.name === "AbortError") return;

          throw e;
        })
        .finally(() => {
          organizationsLoading.value = false;
        });
    }, 300);
  }

  const onSelectFocus = () => {
    _setInitPartnersToList();
    isFocused.value = true;
  };
  const onSelectBlur = () => {
    isFocused.value = false;
    search.value = null;
  };
  const onOrgSelect = (values: (OrganizationViewInterface | string)[]) => {
    const filteredValues = values.filter(x => typeof x !== "string");

    order.value.forwardToOrganizations = filteredValues as OrganizationViewInterface[];
  };
  const onOnlyEdmOrganizationsChange = async () => {
    order.value.forwardToOrganizations = [];

    if (order.value.executorOrganization == null) return;
    await loadPartners(order.value.executorOrganization.id);
  };

  watch(
    () => order.value.executorOrganization,
    async org => {
      order.value.forwardToOrganizations = [];
      organizations.value = [];

      if (order.value.orderType != OrderTypeEnum.ToHub) return;

      await getDefaultConditionValues();

      if (org != null) await loadPartners(org.id);
    }
  );

  watch(
    () => order.value.forwardToOrganizations,
    forwardToOrganizations => {
      if (!forwardToOrganizations) return;
      const ogrIdsSet = new Set(forwardToOrganizations.map(o => o.id));
      selectedOrganizationGroups.value.forEach(g => {
        const hasMatchingOrgs = g.organizations?.every(org =>
          ogrIdsSet.has(org.id)
        );
        if (!hasMatchingOrgs) {
          selectedOrganizationGroups.value = selectedOrganizationGroups.value.filter(
            i => i.id !== g.id
          );
        }
      });
      order.value.forwardToOrganizationGroups = selectedOrganizationGroups.value.map(
        item => item.id
      );
    }
  );

  watch(showOnlyEdmOrganizationsCheck, (show: boolean) => {
    if (show) return;

    onlyEdmOrganizations.value = false;
  });

  watch(isVisibleToOpenHubCheck, (isVisibleToOpenHub: boolean) => {
    if (isVisibleToOpenHub) {
      order.value.isAuctionAutoExtend = false;
      order.value.isAuctionAutoComplete = false;
      return;
    }

    order.value.isVisibleToOpenHub;
  });

  const {
    baseConditionsDescription,
    showConditionFields,
    isOpenHub
  } = useConditionsDescription(
    order,
    availableGenContracts,
    selectedNotPartners,
    filteredForwardingOrganizations
  );

  return {
    organizations,
    organizationGroups,
    filteredForwardingOrganizations,
    allPartnersSelected,
    isInBlackListWarning,
    search,
    organizationsLoading,
    selectedOrganizationGroups,
    selectedNotPartners,
    selectedNotPartnersTitles,
    selectedPartners,
    noDataMessage,
    onlyEdmOrganizations,
    showOnlyEdmOrganizationsCheck,
    availableGenContracts,
    baseConditionsDescription,
    showConditionFields,
    isOpenHub,
    getDefaultConditionValues,
    selectOrderType,
    selectAllPartners,
    selectExecutorRoleType,
    onSelectFocus,
    onSelectBlur,
    onSearchInput,
    onOrgSelect,
    onAutocompleteChange,
    onUpdateSelectedGroups,
    onSelectGroup,
    onOnlyEdmOrganizationsChange
  };
}
