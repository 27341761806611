import { computed, ComputedRef, Ref, watch } from "vue";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import { OrganizationViewInterface } from "@/models/global/OrganizationView.interface";
import { OrderTypeEnum } from "@/models/order/OrderType.enum";

export default function useOptionsControl(
  order: Ref<OrderItemInterface>,
  filteredForwardingOrganizations: ComputedRef<OrganizationViewInterface[]>,
  selectedNotPartners: ComputedRef<OrganizationViewInterface[]>
) {
  const isVisibleToOpenHubShow = computed(
    () =>
      filteredForwardingOrganizations.value?.length >= 2 &&
      order.value.orderType === OrderTypeEnum.ToHub
  );

  const isAuctionAutoExtendDisabled = computed(
    () => order.value.isVisibleToOpenHub
  );

  const isHubOrder = computed(
    () =>
      order.value.orderType === OrderTypeEnum.ToHub &&
      filteredForwardingOrganizations.value &&
      filteredForwardingOrganizations.value.length === 0
  );

  const isAuctionAutoCompleteShow = computed(
    () => !(order.value.isVisibleToOpenHub || isHubOrder.value)
  );

  const isConditionsShow = computed(
    () =>
      isHubOrder.value ||
      order.value.isVisibleToOpenHub ||
      selectedNotPartners.value.length > 0
  );

  const isAuctionShow = computed(
    () =>
      order.value.orderType === OrderTypeEnum.ToHub &&
      filteredForwardingOrganizations.value &&
      filteredForwardingOrganizations.value.length !== 1
  );

  watch(isVisibleToOpenHubShow, (isVisibleToOpenHub: boolean) => {
    if (!isVisibleToOpenHub) order.value.isVisibleToOpenHub = false;
  });

  watch(isAuctionAutoCompleteShow, disabled => {
    if (disabled) {
      order.value.isAuctionAutoExtend = false;
      order.value.isAuctionAutoComplete = false;
    }
  });

  return {
    isVisibleToOpenHubShow,
    isHubOrder,
    isAuctionShow,
    isConditionsShow,
    isAuctionAutoCompleteShow,
    isAuctionAutoExtendDisabled
  };
}
