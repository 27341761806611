





































































import DatePicker from "@/components/UI/DatePicker.vue";
import TimePicker from "@/components/UI/TimePicker.vue";
import { toRef, defineComponent, PropType, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { PeriodItemInterface } from "@/models/order/PeriodItem.interface";
import { periodDateItemRules } from "@/validations/order/periodDateItemRules";
import OrderService from "@/models/order/Order.service";
import { formatDate, parseDateString } from "@/utils/dateUtils";

export default defineComponent({
  name: "PeriodDateItem",
  components: {
    DatePicker,
    TimePicker
  },
  props: {
    id: {
      type: String as PropType<string>,
      default: ""
    },
    period: {
      type: Object as PropType<PeriodItemInterface>,
      required: true
    },
    isUnloadingType: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    loadingDate: {
      type: Object as PropType<PeriodItemInterface | null>,
      default: null
    }
  },
  setup(props) {
    const period = toRef(props, "period");
    const isUnloadingType = toRef(props, "isUnloadingType");
    const loadingDate = toRef(props, "loadingDate");
    const isInterval = computed(() => {
      return period.value.isInterval;
    });

    const rules = periodDateItemRules(
      isUnloadingType,
      loadingDate,
      period,
      isInterval
    );

    const setHour = (hour: string, isHourTo = false) => {
      OrderService.setDatePeriodHour(period.value, hour, isHourTo);
    };

    const v$ = useVuelidate(rules, period);
    function changeDateType(value: boolean): void {
      period.value.isInterval = value;

      if (!value) return;
      period.value.dateTo = "";
      period.value.hourTo = "";
    }

    const minDateFrom = computed(() => {
      const currentDate = new Date();
      const maxLoadingDate =
        isUnloadingType && loadingDate.value?.isInterval
          ? parseDateString(loadingDate.value?.dateTo)
          : isUnloadingType
          ? parseDateString(loadingDate.value?.dateFrom)
          : null;

      if (maxLoadingDate != null)
        return formatDate(maxLoadingDate, "yyyy-MM-dd");

      return formatDate(currentDate, "yyyy-MM-dd");
    });

    return {
      v$,
      minDateFrom,
      changeDateType,
      setHour
    };
  }
});
