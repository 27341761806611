
































import { defineComponent, PropType } from "vue";
import useGetCites from "@/components/order/city/compositions/useGetCites";
import CountryFlag from "vue-country-flag";

export default defineComponent({
  components: { CountryFlag },
  props: {
    id: {
      type: String as PropType<string>,
      default: ""
    },
    countryCode: {
      required: true,
      type: String
    }
  },
  emits: ["update:country-code"],
  setup() {
    const { isLoading, countries } = useGetCites();

    return {
      countries,
      isLoading
    };
  }
});
