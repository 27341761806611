











































































































import InputMask from "@/components/UI/InputMask.vue";
import { DimensionsFormInterface } from "@/models/order/DimensionsForm.interface";
import { computed, defineComponent, PropType, toRefs } from "vue";
import useVuelidate from "@vuelidate/core";
import { dimensionsRules } from "@/validations/order/dimensionsRules";
import { required } from "@vuelidate/validators";
import convertStringToNumber from "@/utils/convertStringToNumber";

export default defineComponent({
  name: "Dimensions",
  components: { InputMask },
  props: {
    id: {
      type: String as PropType<string>,
      default: ""
    },
    title: {
      type: String as PropType<string>,
      default: ""
    },
    dimensions: {
      type: Object as PropType<DimensionsFormInterface>,
      required: true
    },
    placesCount: {
      type: String,
      required: true
    },
    readonlyFields: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    orderPlacesLength: {
      type: Number as PropType<number>,
      default: 1
    }
  },
  emits: ["calcVolume", "update:dimension", "update:places-count"],
  setup(props, { emit }) {
    const {
      dimensions,
      readonlyFields,
      placesCount,
      orderPlacesLength
    } = toRefs(props);
    const rules = computed(() => {
      return dimensionsRules();
    });
    const rulesModel = computed(() => {
      return {
        dimensions,
        placesCount
      };
    });

    const v$ = useVuelidate(
      {
        dimensions: rules,
        placesCount: {
          required,
          minValue(value: unknown) {
            const isHigher =
              convertStringToNumber(value as string) >= orderPlacesLength.value;

            return isHigher
              ? true
              : {
                  $valid: false,
                  message: `Минимум ${orderPlacesLength.value} шт.`
                };
          }
        }
      },
      rulesModel
    );

    const onDimensionsInput = (
      dimensionName: keyof DimensionsFormInterface,
      value: string
    ) => {
      if (readonlyFields.value) return;

      emit(`update:dimension`, { dimensionName, value });
    };

    const onPlacesCountInput = (value: string) => {
      if (readonlyFields.value) return;
      emit(`update:places-count`, value);
    };

    return {
      v$,
      onDimensionsInput,
      onPlacesCountInput
    };
  }
});
