









































































































































































import BaseInput from "@/components/UI/BaseInput.vue";
import InputMask from "@/components/UI/InputMask.vue";
import fullNameMask from "@/mixins/fullNameMask";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import {
  ComputedRef,
  defineComponent,
  inject,
  PropType,
  ref,
  Ref,
  toRef
} from "vue";
import { usePersonValidation } from "@/components/personItem/usePersonValidation";
import { useTaxTypes } from "@/components/personItem/useTaxTypes";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import { usePersonInnFieldViewConfig } from "@/components/personItem/usePersonInnFieldViewConfig";
import CountrySelect from "@/components/order/city/CountrySelect.vue";
import { usePersonMain } from "@/components/personItem/usePersonMain";
import { usePersonSearch } from "@/components/personItem/usePersonSearch";

export default defineComponent({
  name: "PersonItemFtl",
  components: {
    CountrySelect,
    InputMask,
    BaseInput
  },
  props: {
    id: {
      type: String as PropType<string>,
      default: ""
    },
    person: {
      type: Object as PropType<PersonItemInterface>,
      required: true
    },
    type: {
      type: [String, Number] as PropType<OrganizationTypeEnum>,
      default: OrganizationTypeEnum.Customer
    }
  },
  emits: ["update:inn"],
  setup(props, { emit }) {
    const _organizationType = toRef(props, "type");
    const _person = toRef(props, "person");
    const hasEdm: Ref<boolean> = inject("hasEdm") ?? ref<boolean>(false);
    const edmAvailable: ComputedRef<boolean> | undefined = inject(
      "edmAvailable"
    );

    const { taxesTypes, getTaxType } = useTaxTypes();
    const {
      setInn: setInnInternal,
      changePersonType,
      setCountryCode,
      selectedPerson
    } = usePersonMain(_person, _organizationType, taxesTypes);
    const setInn = async (value: string | PersonItemInterface) => {
      await setInnInternal(value);

      emit("update:inn", typeof value === "string" ? value : value.inn);
    };
    const { returnLabelForInn } = usePersonInnFieldViewConfig();
    const {
      onOrgSearch,
      personList,
      searchQuery,
      isSearchLoading
    } = usePersonSearch(_person, _organizationType);
    const { v$ } = usePersonValidation(
      _person,
      _organizationType.value,
      hasEdm
    );

    return {
      v$,
      fullNameMask,
      taxesTypes,
      searchQuery,
      personList,
      selectedPerson,
      hasEdm,
      isSearchLoading,
      edmAvailable,
      getTaxType,
      setInn,
      changePersonType,
      setCountryCode,
      returnLabelForInn,
      onOrgSearch
    };
  }
});
